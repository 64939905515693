import React, { useEffect, useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import BlockContainer from "../Core/Container/BlockContainer"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import Circle from "../../resources/img/why-trust-us/ellipse-888.svg"

const PointsBlockContainer = styled.div`
  margin: 81px 0;
`

const Point = styled.div`
  font-size: 24px;
  line-height: 170%;
  text-align: center;
  color: ${colors.grey};
  :nth-of-type(even) {
    ${fonts.canelaThin};
  }
  :nth-of-type(odd) {
    ${fonts.labGrotesqueLight};
  }

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    font-size: 40px;
    color: ${(props) => (props.active ? colors.clay : colors.grey)};
    transition: all 1s;
  }
`

const CircleImage = styled.img`
  width: 888px;
  height: 888px;

  @media (min-width: ${breakpoints.xl}) {
  }
`

const PointsContainer = styled.div`
  padding: 0 28px;
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 99px;
    padding-right: 95px;
  }
`

const LeafImage = styled.img`
  position: absolute;
  bottom: 80px;
  right: 0;
  height: 616px;
`

const CircleBlock = styled.div`
  position: absolute;
  right: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-right: 94px;
  }
`

const StyledCol = styled(Col)`
  position: relative;
  min-height: 888px;
`

const PointsBlock = (props) => {
  const [active, setActive] = useState(0)
  const { points, images } = props

  
  useEffect(() => {
    const cyclePoints = () => {
      setActive((prevActive) => (prevActive + 1) % points.length)
    }
    const interval = setInterval(cyclePoints, 2500)
    return () => clearInterval(interval)
  }, [points])


  let leafImage = images && images[0]

  return (
    <PointsBlockContainer>
      <BlockContainer>
        <Row>
          {leafImage && (
            <StyledCol hiddenMdDown={"true"} md={5} lg={6}>
              <CircleBlock>
                <CircleImage src={Circle} alt="circle" />
                <LeafImage
                  src={leafImage.image.file.url}
                  alt={leafImage.altText}
                />
              </CircleBlock>
            </StyledCol>
          )}
          <Col md={leafImage ? 7 : 12} mdAlignSelf="center" lg={leafImage ? 6 : 12}>
            <PointsContainer>
              {points.map((point, index) => {
                return (
                  <Point active={active === index ? 1 : 0} key={index}>{point.point}</Point>
                )
              })}
            </PointsContainer>
          </Col>
        </Row>
      </BlockContainer>
    </PointsBlockContainer>
  )
}

export default PointsBlock
