import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import BlockContainer from "../Core/Container/BlockContainer"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import Half from "../../resources/img/core/background/half.png"
import TextImageBlock from "./TextImageBlock"

const Circle = styled.div`
  background-image: url(${Half});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 50vw;
  transform: scaleY(-1);

  @media (min-width: ${breakpoints.xxl}) {
    background-size: 100% 800px;
  }
`

const TitleContainer = styled.div`
  text-align: center;
  margin-top: -50vw;
  padding-top: 114px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 167px;
  }
`

const Title = styled.h1`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 100%;
  @media (min-width: ${breakpoints.md}) {
    max-width: 600px;
    margin: auto;
    font-size: 72px;
  }
`

const SubHeader = styled.div`
  ${fonts.labGrotesqueLight};
  margin: 14px auto 0;
  font-size: 18px;
  line-height: 160%;

  a {
    color: ${colors.grey};
  }

  b {
    ${fonts.labGrotesqueBold};
  }

  i {
    font-style: italic;
  }
  max-width: 520px;

  @media (min-width: ${breakpoints.md}) {
    margin: 24px auto 0;
  }
`

const PointsBlock = styled.div`
  margin: 78px 0 0;
  @media (min-width: ${breakpoints.md}) {
    margin: 118px 0 0;
  }
  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 118px;
  }
`

const Point = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 12px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  margin-bottom: 72px;
  padding: 0 18px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 138px;
  }
`

const PointIcon = styled.div`
  height: 58px;
  margin-bottom: 55px;
  display: flex;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const IntroBlock = (props) => {
  const { header, subHeader, references } = props

  let points = []
  let blocks = []

  const renderBlocks = () => {
    references.forEach((reference, index) => {
      if (reference.__typename === "ContentfulTextImageBlock") {
        blocks.push(<TextImageBlock {...reference} />)
      }
      if (reference.__typename === "ContentfulPoint") {
        points.push(reference)
      }
      if (
        points.length !== 0 &&
        index ===
          references.map((el) => el.__typename).lastIndexOf("ContentfulPoint")
      ) {
        blocks.push(
          <ContentContainer>
            <PointsBlock>
              <Row justifyContent="center">
                {points.map((point, index) => {
                  return (
                    <Col xs={6} sm={6} md={4} lg={2} key={index}>
                      <Point>
                        <PointIcon>
                          <img src={point.icon.file.url} alt={point.point} />
                        </PointIcon>
                        <div>{point.point}</div>
                      </Point>
                    </Col>
                  )
                })}
              </Row>
            </PointsBlock>
          </ContentContainer>
        )
      }
    })
    return <>{blocks}</>
  }

  return (
    <React.Fragment>
      <Circle />
      <BlockContainer>
        <TitleContainer>
          <ContentContainer>
            <Title>{header}</Title>
            {subHeader && (
              <SubHeader>{renderRichText(subHeader)}</SubHeader>
            )}
          </ContentContainer>
        </TitleContainer>
        {renderBlocks()}
      </BlockContainer>
    </React.Fragment>
  )
}

export default IntroBlock
