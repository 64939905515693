import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import { GatsbyImage } from "gatsby-plugin-image"

const TextImageBlockContainer = styled.div`
  background: ${colors.ivory};
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;
  
  @media (min-width: ${breakpoints.lg}) {
    max-width: 678px;
    ${props => props.left? "margin-right: auto" : "margin-left: auto"};
  }
`

const StyledCol = styled(Col)`
  margin-top: 53px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin-top: 0;
    ${(props) => (props.right ? "padding-left: 99px" : "padding-right: 99px")};
    ${(props) => (props.right ? "padding-right: 58px" : "padding-left: 66px")};
  }
`

const Content = styled.div`
  text-align: center;
  color: ${colors.grey};

  h2 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 180%;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  blockquote {
    p {
      font-size: 12px;
      line-height: 180%;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }
`

const TextImageBlock = (props) => {
  const { image, imagePosition, content } = props
  return (
    <TextImageBlockContainer>
      <ContentContainer>
        <Row>
          <Col lg={6}>
            <StyledImage image={image.gatsbyImageData} alt={image.title} left={imagePosition ? 1 : 0}/>
          </Col>
          <StyledCol
            lg={6}
            lgOrder={imagePosition ? "last" : "first"}
            right={imagePosition ? 1 : 0}
            lgAlignSelf="center"
          >
            <Content>{renderRichText(content)}</Content>
          </StyledCol>
        </Row>
      </ContentContainer>
  </TextImageBlockContainer>
  )
}

export default TextImageBlock
