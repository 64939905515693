import React from "react"
import UspBlock from "../Core/USP/UspBlock"
import BlogCarousel from "../Core/BlogCarousel/BlogCarousel"
import LineDivider from "../Core/LineDivider"
import ProductHighlight from "../Core/ProductHighlight"
import PointsBlock from "./PointsBlock"
import IntroBlock from "./IntroBlock"
import FAQBlock from "./FAQBlock"
import Experts from "../Core/Experts"

const PageContent = ({ blocks }) => {
  let pageContents = []
  let points = []
  let faqs = []
  let images = []

  blocks.forEach((block, index) => {
    const type = block.__typename
    // console.log(block)

    if (type === "ContentfulIntroBlock") {
      pageContents.push(<IntroBlock {...block} key={index} />)
    }
    if (type === "ContentfulUspBlock") {
      pageContents.push(<UspBlock {...block} key={index} />)
    }
    if (type === "ContentfulProductHighlightBlock") {
      pageContents.push(<ProductHighlight {...block} key={index} />)
    }
    if (type === "ContentfulArticleCarouselBlock") {
      pageContents.push(<BlogCarousel {...block} key={index} limited />)
    }
    if (type === "ContentfulLineDivider") {
      pageContents.push(<LineDivider {...block} key={index} />)
    }
    if (type === "ContentfulExpertCarousel") {
      pageContents.push(<Experts {...block} key={index} />)
    }
    if (type === "ContentfulPoint") {
      points.push(block)
    }
    if (type === "ContentfulImageBlock") {
      images.push(block)
    }
    if (
      points.length !== 0 &&
      images.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulPoint")
    ) {
      pageContents.push(<PointsBlock points={points} images={images} key={index} />)
    }
    if (type === "ContentfulFaq") {
      faqs.push(block)
    }
    if (
      faqs.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulFaq")
    ) {
      pageContents.push(<FAQBlock faqs={faqs} key={index} />)
    }
  })

  return <>{pageContents}</>
}

export default PageContent
