import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../styles/breakpoints"
import ArrowDown from "../../resources/img/ui/dark/chevron-down-dark.svg"

const FAQContainer = styled.div`
  margin: 111px 0 74px;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.md}) {
    margin: 69px 0 151px;
  }
`

const SectionTitle = styled.h2`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  line-height: normal;
  letter-spacing: 3.8px;
`

const NarrowContainer = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 101px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 237px;
  }
`

const Dropdowns = styled.div`
  margin-top: 54px;
  border-top: 1px solid ${colors.grey};

  @media (min-width: ${breakpoints.md}) {
    border-color: ${colors.clay};
    margin-top: 49px;
  }
`

const DropdownMenu = styled.div`
  border-bottom: 1px solid ${colors.grey};
  @media (min-width: ${breakpoints.md}) {
    border-color: ${colors.clay};
  }
`

const Title = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 18px;
  line-height: 160%;
`

const DropdownContent = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 75px;

  p {
    margin-bottom: 25px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }

    a {
      color: ${colors.grey};
    }
  }

  ul {
    margin-bottom: 25px;
    list-style: disc;
    li {
      margin-left: 20px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 88px;
  }
`

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 46px 0 44px;
  cursor: pointer;

  @media(min-width: ${breakpoints.md}) {
    padding: 46px 0 49px;
  }
`

const Arrow = styled.img`
  width: 17px;
  margin-left: 57px;
  transform: ${(props) => props.isDisplayed && "rotate(-180deg)"};
  transition: all 0.5s;
`

const FAQBlock = (props) => {
  const [active, setActive] = useState(null)
  const { faqs } = props

  const handleDropdown = (index) => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  return (
    <FAQContainer>
      <ContentContainer>
        <NarrowContainer>
          <SectionTitle>FAQ</SectionTitle>
          <Dropdowns>
            {faqs.map((faq, index) => {
              const { title, content } = faq
              return (
                <DropdownMenu key={index}>
                  <TitleSection onClick={() => handleDropdown(index)}>
                    <Title>{title}</Title>
                    <Arrow
                      src={ArrowDown}
                      alt="Down arrow"
                      isDisplayed={active === index}
                    />
                  </TitleSection>
                  {active === index && (
                    <DropdownContent>
                      {renderRichText(content)}
                    </DropdownContent>
                  )}
                </DropdownMenu>
              )
            })}
          </Dropdowns>
        </NarrowContainer>
      </ContentContainer>
    </FAQContainer>
  )
}

export default FAQBlock
