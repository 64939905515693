import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContent from "../components/WhyTrustUs/PageContent"

const WhyTrustUsPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data
  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
      />
      <PageContent blocks={page.blocks} />
    </Layout>
  )
}

export default WhyTrustUsPage

export const pageQuery = graphql`
  query WhyTrustUsPageQuery {
    page: contentfulPage(slug: { eq: "why-trust-us" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ...uspBlockFragment
        ... on ContentfulIntroBlock {
          id
          header
          subHeader {
            raw
          }
          references {
            __typename
            ... on ContentfulPoint {
              id
              point
              icon {
                file {
                  url
                }
              }
            }
            ... on ContentfulTextImageBlock {
              id
              imagePosition
              image {
                title
                gatsbyImageData(width: 1356)
              }
              content {
                raw
              }
            }
          }
        }
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ...expertCarouselFragment
        ... on ContentfulArticleCarouselBlock {
          id
          header
          subHeader {
            raw
          }
          filters {
            ... on ContentfulCategory {
              title
              categoryColor
            }
          }
          slides {
            ... on ContentfulArticle {
              category {
                title
                categoryColor
              }
              slug
              title
              thumbnail {
                gatsbyImageData(width: 626, aspectRatio: 1)
              }
            }
          }
          ctaLabel
          ctaLink
        }
        ... on ContentfulImageBlock {
          altText
          image {
            file {
              url
            }
          }
        }
        ... on ContentfulPoint {
          id
          point
        }
        ... on ContentfulLineDivider {
          id
          points {
            point
          }
        }
        ... on ContentfulFaq {
          id
          title
          content {
            raw
          }
        }
      }
    }
  }
`
